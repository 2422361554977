import { useNavigate } from "react-router-dom";

export const BoostButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className="rounded-xl flex justify-center items-center gap-3"
      onClick={() => {
        navigate("/boosts");
      }}
    >
      <img src="./img/rocket.png" className="w-7" />
      <div className="text-[#31BDB1] uppercase font-black text-base">Boost</div>
    </div>
  );
};
