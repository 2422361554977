import React, { useContext, useEffect, useRef, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";
import { NotifyContext } from "../../contexts/NotifyContext";

const accordianData = [
  {
    title: "DELETE ACCOUNT",
  },
];

const DeleteAccount = ({ id }) => {
  const [openItem, setOpenItem] = useState(null);

  const handleToggle = (value) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === value ? null : value));
  };

  const handleCancel = () => {
    setOpenItem(null);
  };

  return (
    <div className="w-full">
      {accordianData.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            title={item.title}
            isOpen={openItem === index}
            onToggle={() => handleToggle(index)}
            onCancel={handleCancel}
            id={id}
          />
        );
      })}
    </div>
  );
};

const AccordionItem = ({ title, isOpen, onToggle, onCancel, id }) => {
  const contentRef = useRef(null);
  const notifyContext = useContext(NotifyContext);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0";
    }
  }, [isOpen]);

  const deleteUser = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/users/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        setIsLoading(false);
        notifyContext?.setNotify({
          status: "ok",
          message: result.message,
          className: "h-96",
        });
        if (typeof window.Telegram.WebApp !== "undefined") {
          setTimeout(() => {
            window.Telegram.WebApp.close();
          }, 3000);
        }
      } else {
        setIsLoading(false);
        const errorData = await response.json();
        console.error("Failed to delete user:", errorData);
        notifyContext?.setNotify({
          status: "error",
          message: "Failed to delete user",
          className: "h-96",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
      notifyContext?.setNotify({
        status: "error",
        message: error,
        className: "h-96",
      });
    }
  };

  return (
    <div>
      <div
        className={`flex  px-5 py-3 gap-3 rounded-3xl min-h-[68px] m-[1px] bg-[#333A48B2] items-center`}
      >
        <div className="w-full">
          <button
            className="flex items-center justify-between w-full"
            onClick={onToggle}
          >
            <div className="flex  items-center justify-start">
              <span className={`text-sm font-bold text-white`}>{title}</span>
            </div>
            <span
              className={`text-[#C0C9DD] transition-all duration-200 transform text-xl ${
                isOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              <FaAngleRight />
            </span>
          </button>
          <div
            className="overflow-hidden transition-all duration-200"
            ref={contentRef}
            style={{ maxHeight: "0" }} // Ensuring default state for smooth transition
          >
            <div className="text-[#ffffff] mt-5 flex justify-start gap-4">
              <button
                className="bg-gray-500 text-white px-4 py-1 rounded-md hover:bg-gray-600 duration-200"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                onClick={deleteUser}
                className="w-[150px] flex items-center justify-center bg-red-500 text-white px-4 py-1 rounded-md hover:bg-red-600 duration-200"
              >
                {!isLoading ? (
                  "Delete Account"
                ) : (
                  <div className="loader mx-auto"></div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
