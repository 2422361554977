import { useContext, useState } from "react";
import TonButton from "../../components/TonButton/TonButton";
import { TopControl } from "../../components/TopControl";
import { BigEggSvg } from "./BigEggSvg";
import { getTelegramUser } from "../../services/telegramService";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";

const tgUserId = getTelegramUser().id;
const message = `
🎉 *Billy Rush Airdrop & Monthly Rewards!* 🎉

Hey there, Billy Rush player! Unlock your first airdrop and start earning even more from our partner, Livecards! Here’s how:

1. 🖥 Go to: [https://livecards.net/account/login](https://livecards.net/account/login)
2. 🔗 Click on: "Continue with Telegram"
3. 👤 Login using the same Telegram account that you’re using for Billy Rush.

Once you’ve logged in, you’ll receive your first sign-up airdrop! 🎁

But it doesn’t stop there! 🚀

Every month, Livecards will pick winners at each Billy Rush level and give away amazing prizes like games, gaming credits, subscriptions, or gift cards. 🎮

✨ The higher your level, the bigger the prizes! So keep playing, level up, and increase your chances of winning! ✨

📢 Want to know when the lucky draws are happening? Join the official Livecards Telegram channel for updates: [https://t.me/livecardsofficial](https://t.me/livecardsofficial)
`;
export const Airdrop = () => {
  const [isLoading, setIsLoading] = useState(false);
  const notifyContext = useContext(NotifyContext);

  const handleSendMessage = async () => {
    setIsLoading(true);
    let notify: NotifyMessage;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/admin/send-message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: tgUserId, message }),
        }
      );

      const data = await response.json();

      if (data.error) {
        throw new Error(data.data.message);
      }

      console.log("Message sent:", data);

      notify = {
        message: data.data.message,
        status: "ok",
        className: "h-96",
      };
      notifyContext?.setNotify(notify);
    } catch (error: any) {
      console.error("Error sending message:", error);

      notify = {
        message: error.message,
        status: "error",
        className: "h-96",
      };
      notifyContext?.setNotify(notify);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TopControl name="airdrop" />
      <div className="px-2">
        <div className="max-w-[660px] mx-auto flex flex-col items-center justify-center">
          <h1 className="text-white text-xs font-normal">AirDrop Tasks</h1>
          <p className="text-center text-xs font-normal text-[#677496] mt-3">
            Partnerships are on their way. Tasks will appear below. Complete
            them to participate in the Airdrop.
          </p>
          <p className="text-center text-xs font-bold text-[#677496] mt-3">
            The more BILLIES you collect, the bigger the airdrop value will be!
          </p>
        </div>

        <div className="max-w-[750px] mx-auto ">
          <h1 className="text-center text-white text-xs font-bold mt-5">
            Tasks
          </h1>

          <div className="mt-3 space-y-3">
            <div
              className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-[40px] px-5 py-2 cursor-pointer"
              onClick={handleSendMessage}
            >
              <img
                src="/img/airdrop/img-1.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">
                  LIVECARDS DISCOUNT DROPS
                </h2>
                <div className="flex gap-1 mt-2">
                  <img
                    src="/img/airdrop/lock-icon.svg"
                    className="size-3"
                    alt="Icon"
                  />
                  <p className="text-[#677496] font-bold text-xs">
                    Click to Unlock
                  </p>
                </div>
              </div>
            </div>
            <div
              className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-[40px] px-5 py-2 cursor-pointer"
              onClick={handleSendMessage}
            >
              <img
                src="/img/airdrop/img-2.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">
                  In-Game Credit Drops
                </h2>
                <div className="flex gap-1 mt-2">
                  <img
                    src="/img/airdrop/lock-icon.svg"
                    className="size-3"
                    alt="Icon"
                  />
                  <p className="text-[#677496] font-bold text-xs">
                    Click to Unlock
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-[40px] px-5 py-2 opacity-50">
              <img
                src="/img/airdrop/img-3.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">Token Drop</h2>
                <div className="flex gap-1 mt-2">
                  <p className="text-[#677496] font-bold text-xs">
                    COMMING SOON
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loader-overlay">
          <div className="loader-modal" />
        </div>
      )}
    </>
  );
};
