import React, { useState } from "react";
import { useUser } from "../../hooks/useUser";
import { ReferralLink } from "../../components/ReferralLink";
import { MediumEggSvg } from "../Businesses/MediumEggSvg";
import { EggSvg } from "../Layout/EggSvg";
import { LargerEggSvg } from "../Businesses/LargerEggSvg";
import { useSettings } from "../../hooks/useSettings";
import { Delimiter } from "./Delimiter";
import { Reloader } from "./Reloader";
import { useWebSocket } from "../../hooks/useWebsocket";
import { TopControl } from "../../components/TopControl";

export const Referrals = () => {
  const { user, setUser } = useUser();
  const referrals = user?.referrals;
  const { referralReward, premiumReferralReward } = useSettings();
  const { webSocket } = useWebSocket();
  const [isReferralUpdate, setReferralUpdate] = useState(false);

  const updateReferals = () => {
    setReferralUpdate(true);
    webSocket?.emit("getUser", user?.tgId);
    setTimeout(() => {
      setReferralUpdate(false);
    }, 3000);
  };

  return (
    <>
      <TopControl name="Friends" />
      <div className="p-6 pt-4 rounded-lg max-w-md mx-auto">
        <div className="text-center font-normal">Invite friends!</div>
        <div className="flex justify-start items-center bg-[#333A48B2] rounded-xl mt-8 mb-8 px-3 py-2 gap-4">
          <img src="./img/gift.png" className="w-8" />
          <div>
            <div className="uppercase font-normal	">Invite a Friend</div>
            <div className="flex items-center gap-1 text-sm">
              <img src="./img/coin.png" className="w-5 h-5" />
              <div className="flex items-center font-bold">
                <div className="text-[#E0AE10]">+</div>
                <div className="text-[#E0AE10]">{referralReward}</div>
              </div>
              <div className="text-[#677496]">Billies</div>
            </div>
          </div>
        </div>
        <div className="flex justify-start items-center bg-[#333A48B2] rounded-xl mt-8 mb-8 px-3 py-2 gap-4">
          <img src="./img/gift.png" className="w-8" />
          <div>
            <div className="uppercase font-normal	">
              Invite with Telegram Premium
            </div>
            <div className="flex items-center gap-1 text-sm">
              <img src="./img/coin.png" className="w-5 h-5" />
              <div className="flex items-center font-bold">
                <div className="text-[#E0AE10]">+</div>
                <div className="text-[#E0AE10]">{premiumReferralReward}</div>
              </div>
              <div className="text-[#677496]">Billies</div>
            </div>
          </div>
        </div>
        <div className="text-center mb-2 font-normal">
          List of invited friends
        </div>
        <ul
          className="list-none p-4 rounded-xl bg-[#22252BB2]"
          style={{ maxHeight: window.innerHeight - 490, overflowY: "scroll" }}
        >
          {/* <div onClick={updateReferals} className="absolute right-0 mr-16">
            <Reloader className={isReferralUpdate ? "animate-spin" : ""} />
          </div> */}

          {referrals?.length && referrals?.length > 0 ? (
            referrals?.map((referral, index) => (
              <>
                <li
                  key={referral.tgId}
                  className="pl-3 pt-1 pb-1 pr-0 my-1 rounded-md flex items-center shadow-sm w-full"
                >
                  <div className="flex text-md justify-center items-center border-2 border-white rounded-full w-10 h-10 mr-2">
                    {referral.firstName[0]}
                  </div>
                  <div className="flex flex-row">
                    <div className="text-md">{referral.firstName}</div>
                    <div className="text-md ml-2">@{referral.tgUsername}</div>
                  </div>
                </li>
                <Delimiter />
              </>
            ))
          ) : (
            <div className="flex flex-col justify-center items-center mt-6 mb-6">
              <div className="text-xs text-[#67749680]">
                You don't have invited friends
              </div>
            </div>
          )}
        </ul>
        <ReferralLink className="mt-4" />
      </div>
    </>
  );
};
