import { useEffect, useRef, useState } from "react";
import { SmallEggSvg } from "../../components/SmallEggSvg";
import { Business } from "../../models";
import { formatNumber } from "../../utils/formatNumber";

export const BuyBusiness = ({
  business,
  onBuyBusiness,
  onClose,
}: {
  business: Business;
  onBuyBusiness: any;
  onClose: any;
}) => {
  const [visible, setVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVisible(true); // Trigger the animation on mount

    return () => {
      setVisible(false); // Clean up on unmount
    };
  }, []);

  const handleClose = () => {
    setVisible(false); // Trigger the exit animation
    setTimeout(onClose, 300); // Wait for the animation to finish before closing
  };

  return (
    <>
      <div className="overlay" onClick={handleClose} />
      <div
        id="modal"
        className={`modal ${visible ? "visible" : "hidden"}`}
        ref={modalRef}
      >
        <img src={business?.avatarUrl} className="w-16 h-16 rounded-full" />
        <div className="text-xl mt-6 uppercase">{business?.name}</div>
        <div className="text-sm text-[#677496]">{business?.description}</div>
        <div className="mt-6 flex">
          <div className="flex flex-col justify-center items-left">
            <div className="text-sm font-light">Profit per hour</div>
            <div className="flex justify-left items-center">
              <img src="./img/coin.png" className="w-6" />
              <div className="ml-1 text-[#E0AE10]">
                {formatNumber(business?.rewardPerHour)}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-left ml-4">
            <div className="text-sm font-light">Price</div>
            <div className="flex justify-left items-center">
              <img src="./img/coin.png" className="w-6" />
              <div className="ml-1 text-[#E0AE10]">{formatNumber(business?.price)}</div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            onBuyBusiness(business?.id);
          }}
          className="mt-4 py-4 px-6 text-sm rounded-lg bg-[#288CEE] w-full mb-8"
        >
          Buy this
        </button>
      </div>
    </>
  );
};
