import React from "react";

const MaintenanceNotice: React.FC = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-start pt-10">
      <div className="text-xl uppercase text-center font-bold my-6">Billy rush</div>

      <div className="flex justify-center">
        <img className="w-8/12 z-10" src="./img/main_goat.png" />
        <img src="./img/circles.png" className="w-8/12 absolute mt-20" />
      </div>
      <div className="uppercase text-xl font-bold mt-6">Technical works</div>
    </div>
  );
};

export default MaintenanceNotice;
