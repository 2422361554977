import React, { useContext, useEffect, useState } from "react";
import { TopControl } from "../../components/TopControl";
import LanguageSelector from "../../components/Settings/LanguageSelector";
import DeleteAccount from "../../components/Settings/DeleteAccount";
import { useUser } from "../../hooks/useUser";
import { NotifyContext } from "../../contexts/NotifyContext";
import { useWebSocket } from "../../hooks/useWebsocket";
import { getTelegramUser } from "../../services/telegramService";
import { User } from "../../models";

const Settings = () => {
  const { user, setUser } = useUser();
  const [isHapticTouch, setIsHapticTouch] = useState(
    user?.isHapticTouch || false
  );
  const notifyContext = useContext(NotifyContext);
  const { webSocket } = useWebSocket();

  const handleToggle = async (event: any) => {
    const hapticTouch = event.target.checked;
    setIsHapticTouch(hapticTouch);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_API_URL}/users/haptic-feedback/${user?._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ isHapticTouch: hapticTouch }),
        }
      );

      if (response.ok) {
        if (hapticTouch) {
          notifyContext?.setNotify({
            status: "ok",
            message: `Haptic touch enabled`,
            className: "h-80",
          });
        } else {
          notifyContext?.setNotify({
            status: "ok",
            message: `Haptic touch disabled`,
            className: "h-80",
          });
        }
        const tgUser = getTelegramUser();
        if (webSocket?.connected && tgUser.id !== -1) {
          webSocket.on("user", handleGetUser);
          webSocket.emit("getUser", tgUser.id);
        }
      } else {
        throw new Error("Failed to update user");
      }
    } catch (error) {
      console.error("Error:", error);
      notifyContext?.setNotify({
        status: "error",
        message: `Error in ${
          hapticTouch ? "enabling" : "disabling"
        } haptic touch`,
        className: "h-80",
      });
    }
  };

  const handleGetUser = (userData: User) => {
    if (setUser) {
      setUser(userData);
    }
  };

  return (
    <div>
      <TopControl name="Settings" />
      <div className="mt-10 max-w-[748px] mx-auto px-5">
        <LanguageSelector />
      </div>
      <div className="mt-10 max-w-[748px] mx-auto px-5">
        <DeleteAccount id={user?._id} />
      </div>
      <div className="mt-10 max-w-[748px] mx-auto px-5">
        <div className="space-y-5">
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-light">HAPTIC FEEDBACK</h2>
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isHapticTouch}
                onChange={handleToggle}
              />
              <div className="toggle-switch-background">
                <div className="toggle-switch-handle"></div>
              </div>
            </label>
          </div>
          <div className="flex justify-between items-center">
            <h2 className="text-sm font-light">COINS ANIMATION</h2>
            <label className="toggle-switch">
              <input type="checkbox" />
              <div className="toggle-switch-background">
                <div className="toggle-switch-handle"></div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
