import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { formatNumber } from "../utils/formatNumber";

export const League = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  if (!user) {
    return <div />;
  }

  return (
    <div
      onClick={() => {
        navigate("/league");
      }}
      className="w-full justify-between items-center flex cursor-pointer gap-0.5"
    >
      <div
        style={{
          height: "10px",
          backgroundColor: "#131313",
        }}
        className="rounded-xl w-[50%]"
      >
        <div
          style={{
            height: "100%",
            width: (user?.score / user?.league.maxScore) * 100 + "%",
            maxWidth: "100%",
            backgroundColor: "#288CEE",
          }}
          className="rounded-xl text-sm"
        />
      </div>
      <div className="text-[#288CEE] text-sm max-w-10 truncate text-nowrap">
        {user.league.name}
      </div>
    </div>
  );
};
