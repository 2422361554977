import { ArrowSvg } from "../../components/ArrowSvg";
import { SmallEggSvg } from "../../components/SmallEggSvg";

export const TaskList = ({
  tasks,
  handleTaskClick,
  filter,
}: {
  tasks: any;
  handleTaskClick: any;
  filter: string;
}) => {
  return (
    <>
      {tasks.map((task: any) => (
        <li
          key={task.id}
          className="p-1 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm"
          onClick={() => handleTaskClick(task)}
        >
          <div className="w-full flex flex-row justify-left items-center">
            <img
              src={task.avatarUrl}
              className="mr-2 rounded-full w-16 h-16 p-3 object-scale-down"
            />
            <div>
              <span
                className={
                  task.completed
                    ? "text-xs font-light	text-gray-500 line-through leading-none line-clamp-1"
                    : "text-xs font-light text-white leading-none line-clamp-1"
                }
              >
                {task.name}
              </span>
              <span className="flex justify-left ml-2 items-center">
                <img src="./img/coin.png" className="w-5 h-5" />
                <div className="ml-1 text-xs text-[#E0AE10] font-bold">
                  +{task.rewardAmount}
                </div>
                <div className="text-xs text-[#677496] font-bold ml-1">
                  Billies
                </div>
              </span>
            </div>
          </div>
        </li>
      ))}
    </>
  );
};
