import React from "react";
import "./Loader.css";

const Loader: React.FC = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-around pt-10">
      <div className="text-xl uppercase text-center font-bold">Billy rush</div>
      <div className="text-md font-light uppercase text-center">
        "Tap and Tote, Become the GOAT!"
      </div>
      <div className="flex justify-center">
        <img className="w-8/12 z-10 max-w-[320px]" src="./img/main_goat.webp" />
        <img src="./img/circles.png" className="w-8/12 absolute mt-20" />
      </div>
      <div className="flex flex-col justify-center items-center z-10 mt-4">
        <div className="text-[#515A70] uppercase text-xl">Loading...</div>
        <div className="text-sm font-light text-center px-20 mt-4">
          Stay tuned for more info in official channels:
        </div>
        <div className="p-2 bg-[#333A4833] gap-4 flex rounded-full">
          <img
            className="w-12"
            src="./img/tg.png"
            onClick={() => window.open(process.env.REACT_APP_TG_LINK, "_blank")}
          />
          <img
            className="w-12"
            src="./img/yt.png"
            onClick={() => window.open(process.env.REACT_APP_YT_LINK, "_blank")}
          />
          <img
            className="w-12"
            src="./img/x.png"
            onClick={() => window.open(process.env.REACT_APP_X_LINK, "_blank")}
          />
        </div>
        <div className="text-center text-[#515A70] font-light">
          A Game Goat Game
        </div>
      </div>
    </div>
  );
};

export default Loader;
