import React, { useEffect, useRef, useState } from "react";
import { FaAngleRight } from "react-icons/fa6";

const accordianData = [
  {
    title: "SELECT LANGUAGE",
    selectedLanguage: "English",
    languages: ["English", "Thailand", "Spanish"],
  },
];

const LanguageSelector = () => {
  const [openItem, setOpenItem] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const handleToggle = (value) => {
    setOpenItem((prevOpenItem) => (prevOpenItem === value ? null : value));
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setOpenItem(null);
  };

  return (
    <div className="w-full">
      {accordianData.map((item, index) => {
        return (
          <AccordionItem
            key={index}
            title={item.title}
            languages={item.languages.filter(
              (lang) => lang !== selectedLanguage
            )}
            selectedLanguage={selectedLanguage}
            isOpen={openItem === index}
            onToggle={() => handleToggle(index)}
            onLanguageSelect={handleLanguageSelect}
          />
        );
      })}
    </div>
  );
};

const AccordionItem = ({
  title,
  selectedLanguage,
  languages,
  isOpen,
  onToggle,
  onLanguageSelect,
}) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0";
    }
  }, [isOpen]);

  return (
    <div>
      <div
        className={`flex items-start px-5 py-3 gap-3 rounded-3xl m-[1px] bg-[#333A48B2]`}
      >
        <div className="w-full">
          <button
            className="flex items-center justify-between w-full"
            onClick={onToggle}
          >
            <div className="flex flex-col items-start justify-start">
              <span className={`text-sm text-white`}>{title}</span>
              <p className="text-[#479DF8] font-bold">{selectedLanguage}</p>{" "}
            </div>
            <span
              className={`text-[#C0C9DD] transition-all duration-200 transform text-xl ${
                isOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              <FaAngleRight />
            </span>
          </button>
          <div
            className="overflow-hidden transition-all duration-200"
            ref={contentRef}
          >
            <div className="text-[#ffffff] mt-5">
              {languages.map((language, index) => {
                return (
                  <p
                    key={index}
                    className="cursor-pointer hover:bg-white hover:bg-opacity-20 duration-200 pl-2 rounded-md py-1"
                    onClick={() => onLanguageSelect(language)}
                  >
                    {language}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;
