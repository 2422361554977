import React, { useEffect, useState } from "react";
import { getConfig } from "../../../utils/config";
import { User } from "../../../models";

const { adminApiUrl } = getConfig();

const TAKE = 10;

export const AdminUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [balanceSort, setBalanceSort] = useState<"asc" | "desc">("desc");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const refreshUsers = async () => {
    const skip = (page - 1) * TAKE;

    const response = await fetch(
      `${adminApiUrl}/admin/users?skip=${skip}&take=${TAKE}&balanceSort=${balanceSort}`,
      { headers: { "Admin-Token": localStorage.getItem("password") || "" } }
    );
    const data = await response.json();
    setUsers(data.data);
    setTotal(data.total);
  };

  useEffect(() => {
    refreshUsers();
  }, [page]);

  const onNextClick = () => {
    if (page < Math.ceil(total / TAKE)) {
      setPage(page + 1);
    }
  };

  const onPrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const onSortClick = () => {
    const sortedUsers = [...users].sort((a, b) => {
      if (balanceSort === "asc") {
        return a.balance - b.balance; // Ascending order
      } else {
        return b.balance - a.balance; // Descending order
      }
    });

    setUsers(sortedUsers); // Update users state with sorted users
    setBalanceSort(balanceSort === "asc" ? "desc" : "asc"); // Toggle sort order
  };

  const resetUsers = async () => {
    if (!window.confirm("Are you sure?")) return;
    const response = await fetch(`${adminApiUrl}/admin/reset-users`, {
      method: "GET",
      headers: {
        "Admin-Token": localStorage.getItem("password") || "",
      },
    });

    if (response.ok) {
      setIsError(false);
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return (
    <div className="flex flex-col space-y-4 text-black">
      <div className="text-white">Total users - {total}</div>
      <div className="flex flex-row items-start justify-between">
        <button
          className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
          onClick={onSortClick}
        >
          Balance sort: {balanceSort === "asc" ? "asc" : "desc"}
        </button>

        <div className="flex flex-row items-center space-x-2">
          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
            onClick={onPrevClick}
          >
            Prev
          </button>

          <span className="py-2 px-4 bg-slate-50 font-mono text-sm">
            {(page - 1) * TAKE + 1} - {(page - 1) * TAKE + users.length}
          </span>

          <button
            className="py-2 px-4 bg-slate-50 hover:bg-slate-300 font-mono text-sm"
            onClick={onNextClick}
          >
            Next
          </button>
        </div>
      </div>

      <div className="flex flex-col space-y-2 overflow-scroll max-h-[70vh]">
        {users.map((user, index) => (
          <div
            className="flex flex-row items-center bg-slate-50 py-2 px-4"
            key={user.tgId}
          >
            <div className="flex flex-col justify-items-start w-2/5">
              <label className="font-bold">
                {index + 1 + (page - 1) * TAKE}
              </label>
            </div>

            <div className="flex flex-col justify-items-start w-2/5">
              <label className="font-bold">{user.tgUsername}</label>
              <label className="text-xs">{user.tgId}</label>
            </div>

            <div className="flex flex-col justify-items-start">
              <div className="text-xs">balance: {user.balance}</div>
              <div className="text-xs">refs: {user.referrals.length}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col mb-4">
        <h2 className="text-white">Reset users:</h2>
        <button
          className="bg-red-600 hover:bg-red-700 text-white font-light py-1 px-4 w-full font-mono"
          onClick={resetUsers}
        >
          Reset users
        </button>
      </div>

      {isSuccess && (
        <div className="bg-green-400 text-center text-white">
          Settings saved
        </div>
      )}
      {isError && (
        <div className="bg-red-600 text-center text-white">Error</div>
      )}
    </div>
  );
};
