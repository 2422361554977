export const levelBaseImages = [
  "/img/profile-images/1.png",
  "/img/profile-images/2.png",
  "/img/profile-images/3.png",
  "/img/profile-images/4.png",
  "/img/profile-images/5.png",
  "/img/profile-images/6.png",
  "/img/profile-images/7.png",
  "/img/profile-images/8.png",
  "/img/profile-images/9.png",
  "/img/profile-images/10.png",
];
