import React, { useState, useEffect, useRef } from "react";
import { SmallEggSvg } from "../../components/SmallEggSvg";
import { MediumEggSvg } from "../Businesses/MediumEggSvg";

export const BoostModal = ({
  img,
  onClose,
  onPurchase,
  title,
  description,
  price,
}: {
  img: string;
  onClose: any;
  onPurchase: any;
  title: string;
  description: string;
  price?: string;
}) => {
  const [visible, setVisible] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setVisible(true);

    return () => {
      setVisible(false);
    };
  }, []);

  const purchaseHandler = () => {
    handleClose();
    onPurchase();
  };

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 300);
  };

  return (
    <>
      <div className="overlay" onClick={handleClose} />
      <div
        id="modal"
        className={`modal ${visible ? "visible" : "hidden"}`}
        ref={modalRef}
      >
        <div className="flex w-32 items-center justify-center rounded-lg p-2">
          <img src={img} />
        </div>
        <div className="text-lg mt-2 uppercase">{title}</div>
        <div className="text-sm px-8 text-center text-[#677496]">
          {description}
        </div>
        <div className="flex items-center gap-3 uppercase mt-4">
          <img src="./img/coin.png" className="w-8" />
          <div className="text-2xl">{price}</div>
        </div>
        <button
          onClick={purchaseHandler}
          className="mt-4 py-4 px-6 text-sm rounded-lg flex justify-center items-center bg-[#288CEE] mb-8 w-full uppercase gap-3"
        >
          <div>Go ahead</div>
          <svg
            width="14"
            height="14"
            viewBox="0 0 29 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0004 28.5006L26.001 15.5L13.0004 2.49938"
              stroke="white"
              strokeWidth="4"
              stroke-linecap="round"
            />
            <path
              d="M2 14.0001C0.895431 14.0001 9.65645e-08 14.8956 0 16.0001C-9.65645e-08 17.1047 0.89543 18.0001 2 18.0001L2 14.0001ZM2 18.0001L24 18.0001L24 14.0001L2 14.0001L2 18.0001Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </>
  );
};
