import { useContext, useEffect } from "react";
import { useWebSocket } from "../hooks/useWebsocket";
import { NotifyContext } from "../contexts/NotifyContext";
import { useUser } from "../hooks/useUser";
import { DataContext } from "../contexts/DataContext";

export const ComboGame = () => {
  const notifyContext = useContext(NotifyContext);
  const dataContext = useContext(DataContext);
  const { user } = useUser();

  const unlockedCombos = user?.currentComboCompletions || [];
  const totalCombos = 3;

  return (
    <div className="flex justify-between gap-4 mt-4">
      {unlockedCombos.map((b) => {
        const business = dataContext?.businesses.find((cb) => cb.id == b);
        return (
          <div key={b} className="rounded-2xl bg-[#373737CC] w-24 h-24">
            <img
              className="rounded-lg w-24 h-24 object-cover"
              src={business?.avatarUrl}
             
            />
          </div>
        );
      })}
      {Array.from({ length: totalCombos - unlockedCombos.length }).map(
        (_, index) => (
          <div
            key={`combo-placeholder-${index}`}
            className="rounded-2xl bg-[#373737CC] flex items-center justify-center text-white w-24 h-24"
          >
           <img src="./img/secret.png"/>
          </div>
        )
      )}
    </div>
  );
};
