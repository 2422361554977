import React, { useState, useEffect, useContext } from "react";
import { useUser } from "../../hooks/useUser";
import { useWebSocket } from "../../hooks/useWebsocket";
import { Business } from "../../models";
import { MediumEggSvg } from "./MediumEggSvg";
import { LargerEggSvg } from "./LargerEggSvg";
import "./Businesses.css";
import { SmallEggSvg } from "../../components/SmallEggSvg";
import { EggSvg } from "../Layout/EggSvg";
import { BuyBusiness } from "./BuyBusiness";
import { formatNumber, formatNumberWithCommas } from "../../utils/formatNumber";
import { VerticalDivider } from "../../components/VerticalDivider";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";
import { BusinessesList } from "./BusinessesList";
import { DataContext } from "../../contexts/DataContext";
import { BusinessesFilter } from "./BusinessesFilter";
import { TopControl } from "../../components/TopControl";
import { ComboGame } from "../../components/ComboGame";

export const Businesses = () => {
  const { user } = useUser();
  const { webSocket } = useWebSocket();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedBusiness, setSelectedBusiness] = useState<Business>();
  const notifyContext = useContext(NotifyContext);
  const context = useContext(DataContext);
  const [filter, setFilter] = useState<string>("Games".toLocaleLowerCase());
  const [loadingBusinessIds, setLoadingBusinessIds] = useState<string[]>([]);

  useEffect(() => {
    if (webSocket && user) {
      console.log("getBusinessesToBuy");

      webSocket.emit("getBusinessesToBuy", user?.tgId);
    }
  }, [webSocket, user?.tgId]);

  const buyBusiness = (businessId: string) => {
    setLoadingBusinessIds((prev) => [...prev, businessId]); // Включаем лоадер для конкретного бизнеса

    const request = JSON.stringify([user?.tgId, businessId]);

    const business = context?.businesses.find((x) => x.id === businessId);

    if (business?.level === 0) {
      webSocket?.emit("buyBusiness", request);
    } else {
      webSocket?.emit("upgradeBusiness", request);
    }

    setModalOpen(false);
  };

  useEffect(() => {
    if (webSocket) {
      const handleBusinessBought = ({ success, id }: any) => {
        setLoadingBusinessIds((prev) =>
          prev.filter((businessId) => businessId !== id)
        );

        if (success) {
          const business = context?.businesses.find((x) => x.id === id);

          const notifyContent = (
            <div className="flex flex-col justify-center items-center">
              <img
                src={business?.avatarUrl}
                className="w-16 h-16 rounded-full"
              />
              <div className="text-xl mt-6 uppercase">{business?.name}</div>
              <div className="text-sm text-[#677496]">
                {business?.description}
              </div>
              <div>
                {business?.name}
                {business?.level === 0 ? "was purchased" : "was upgraded"}
              </div>
              <div className="mt-6 flex">
                <div className="flex flex-col justify-center items-left">
                  <div className="text-sm font-light">Profit per hour</div>
                  <div className="flex justify-left items-center">
                    <img src="./img/coin.png" className="w-6" />
                    <div className="ml-1 text-[#E0AE10]">
                      {formatNumber(
                        business?.rewardPerHour ? business?.rewardPerHour : 0
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-left ml-4">
                  <div className="text-sm font-light">Price</div>
                  <div className="flex justify-left items-center">
                    <img src="./img/coin.png" className="w-6" />
                    <div className="ml-1 text-[#E0AE10]">
                      {formatNumber(business?.price ? business?.price : 0)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          const notify: NotifyMessage = {
            status: "unknown",
            message: notifyContent,
            className: "h-[420px]",
          };
          notifyContext?.setNotify(notify);
        } else {
          const notify: NotifyMessage = {
            status: "error",
            message: `Deal fell through, try again later`,
            className: "h-72",
          };
          notifyContext?.setNotify(notify);
        }
      };

      webSocket.on("businessBought", handleBusinessBought);

      return () => {
        webSocket.off("businessBought", handleBusinessBought);
      };
    }
  }, [webSocket, context?.businesses, notifyContext]);

  return (
    <>
      <TopControl name="Mine" />
      <div className="p-5 pt-0 rounded-lg max-w-md mx-auto">
        <div className="flex items-center justify-between bg-[#333A4866] px-4 py-2 rounded-full">
          <div className="text-sm">Daily combo</div>
          <div className="flex items-center gap-2">
            {user?.currentComboCompletions.map(() => (
              <div className="w-4 h-4 bg-[#53FF82] rounded-full" />
            ))}
            {Array.from({
              length: 3 - (user?.currentComboCompletions?.length || 0),
            }).map((_, index) => (
              <div
                key={`combo-placeholder-${index}`}
                className="rounded-full bg-[#2E3442] w-4 h-4"
              />
            ))}
          </div>
          <div className="bg-[#288CEE] px-2 py-1 rounded-full flex items-center gap-1">
            <img src="./img/coin.png" className="w-4 h-4" />
            <div>+ {context?.settings?.comboReward}</div>
          </div>
        </div>
        <div className="flex justify-center gap-4 pt-5 pb-3">
          <img src="./img/coin.png" className="w-10" />
          <div className="text-4xl font-extrabold">
            {/* {formatNumber(user?.balance || 0)} */}
            {formatNumberWithCommas(user?.balance || 0)}
          </div>
        </div>
        <div className="mb-4">
          <ComboGame />
        </div>
        <BusinessesFilter
          businesses={context?.businesses}
          onCategorySelect={(category) => {
            setFilter(category);
          }}
        />
        <div
          style={{ maxHeight: window.innerHeight - 355, overflowY: "scroll" }}
        >
          <div className="businesses-container">
            {!!user && context?.businesses && (
              <BusinessesList
                businesses={context?.businesses.filter(
                  (x) => x.category.toLocaleLowerCase() == filter
                )}
                setModalOpen={setModalOpen}
                setSelectedBusiness={setSelectedBusiness}
                user={user}
                loadingBusinessIds={loadingBusinessIds}
              />
            )}
          </div>
        </div>

        {isModalOpen && selectedBusiness && (
          <BuyBusiness
            business={selectedBusiness}
            onBuyBusiness={buyBusiness}
            onClose={() => {
              setModalOpen(false);
            }}
          />
        )}
      </div>
    </>
  );
};
